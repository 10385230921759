@import '@/styles/variants.css';

.react-datepicker__input-container input {
  width: 100%;
  flex: 1;
  padding: 10px;
  font-family: 'Pretendard', sans-serif;
  font-size: 0.9rem;
  border: 1px solid lightgray;
  border-radius: 7px;
  color: darkgray;
  box-sizing: border-box;
}

.react-datepicker {
  width: auto;
  max-width: 100%;
  box-sizing: border-box;
  z-index: 1000;
  position: relative;
  font-family: 'Pretendard', sans-serif;
  border-radius: 10px;
  box-shadow: none;
  background-color: #ffffff;
}

.react-datepicker__tab-loop {
  position: absolute;
  width: 0;
  height: 0;
}

.react-datepicker__header {
  background-color: #ffffff;
  border-bottom: none;
}

.react-datepicker__current-month {
  color: #2f2f2f;
  font-size: 16px;
}

.react-datepicker__day-name {
  color: darkgray;
  font-weight: 600;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected:not([aria-disabled='true']):hover,
.react-datepicker__day--in-selecting-range:not([aria-disabled='true']):hover,
.react-datepicker__day--in-range:not([aria-disabled='true']):hover {
  background-color: var(--primary-color);
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: var(--primary-color-half);
}

.react-datepicker__day--keyboard-selected {
  background-color: var(--primary-color);
  color: white;
}

.react-datepicker__day--selected:not([aria-disabled='true']):hover {
  background-color: var(--primary-color-hover);
  color: white;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: var(--primary-color);
  color: white;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li:hover {
  background-color: var(--primary-color-half);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: var(--primary-color-hover);
  color: white;
}
