:root {
  --primary-color: #698474;
  --secondary-color: #fcf8f3;
  --third-color: #d86f6f;
  --primary-color-hover: #567b65;
  --secondary-color-hover: #efefe4;
  --third-color-hover: #c56660;

  --primary-color-half: rgba(105, 132, 116, 0.5);

  --gray: #e0e0e0;
}
