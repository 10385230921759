@import '@/styles/variants.css';

:root {
  --fc-border-color: var(--primary-color);
  --fc-button-bg-color: var(--secondary-color);
  --fc-button-hover-bg-color: none;
  --fc-button-active-bg-color: var(--primary-color-hover);
  --fc-button-text-color: var(--primary-color);
  --fc-event-bg-color: var(--primary-color);
  --fc-event-border-color: var(--primary-color);
  --fc-today-bg-color: var(--secondary-color);
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  --fc-button-text-color: var(--secondary-color);
}

.fc {
  font-family: 'Pretendard', sans-serif;
}

.fc .fc-highlight {
  background-color: var(--primary-color-half);
}
